import {Component, Input, ElementRef, ViewChild, OnInit, signal, Signal, WritableSignal} from '@angular/core';
import {SearchContainer} from "../../core/definitions/search-container";
import {FeatureFlagsService} from "../../core/feature-flags.service";
import {OperationTarget} from "../../core/definitions/operation-target.enum";
import {OperationService} from "../../operations/operation.service";
import {OperationDialogService} from "../../operations/operation-dialog.service";
import {SuperObjectModel} from "../../core/definitions/super-object-model";
import {SearchTemplateGroupService} from "../search-template-group.service";
import {SearchExecutorService} from "../search-executor.service";
import {SearchResultSelectionsService} from "../search-result-selections.service";
import {Selector} from "../../core/definitions/reference";
import {SelectorCreationParams} from "../../core/definitions/selector-creation-params";
import {SelectorContainer} from "../../core/definitions/selector-container";
import {OperationStepType} from "../../core/definitions/operation-step-type.enum";
import {SearchSelectorService} from "../../core/search-selector.service";
import {BreakpointObserver} from "@angular/cdk/layout";
import {toSignal} from "@angular/core/rxjs-interop";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-search-view-full-v2',
  templateUrl: './search-view-full-v2.component.html',
  styleUrl: './search-view-full-v2.component.scss'
})
export class SearchViewFullV2Component implements OnInit {

  @ViewChild('firstSibling') firstSibling!: ElementRef;
  @ViewChild('secondSibling') secondSibling!: ElementRef;

  @Input() searchContainer: SearchContainer;
  @Input() searchViewType: string;

  filterDrawerOpen: boolean = true;
  isSmallScreen: Signal<boolean> = signal(false);
  loading: WritableSignal<boolean> = signal(true);

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly featureFlagService: FeatureFlagsService,
    private readonly operationService: OperationService,
    private readonly operationDialogService: OperationDialogService,
    private readonly searchExecutorService: SearchExecutorService,
    private readonly searchResultSelectionsService: SearchResultSelectionsService,
    private readonly searchSelectorService: SearchSelectorService,
    private readonly searchTemplateGroupService: SearchTemplateGroupService
  ) {
    this.isSmallScreen = toSignal(this.breakpointObserver.observe('(max-width: 1023px)').pipe(map(result => result.matches)));
  }

  ngOnInit() {
    if (this.searchContainer) {
      this.setOperationContainer().then();
    }

    this.searchExecutorService.subscribeToSearchResult(this.searchContainer, () => {
      if (!this.searchContainer.searching) {
        this.loading.set(false);
      }
      else {
        this.loading.set(true);
      }
    })
  }

  onScroll(): void {
    if (this.secondSibling.nativeElement.scrollTop > 0) {
      this.firstSibling.nativeElement.style.borderBottom = '1px solid var(--sys-outline-variant)';
    } else {
      this.firstSibling.nativeElement.style.borderBottom = 'none';
    }
  }

  private async handleResultObject(resultObject: SuperObjectModel) {
    if (resultObject && resultObject.object_type === 'TemplateModelOverviewFieldsForFieldSelect') {
      this.searchContainer.refreshTemplateOverviewFields = true;
      await this.searchTemplateGroupService.setListFieldTemplateGroup(this.searchContainer, resultObject.template_group_id);
      await this.searchExecutorService.runSearch(this.searchContainer);
    }
  }

  private async setOperations() {
    this.operationService.setOperationContextObjects(
      this.searchContainer.operationContainer,
      this.searchResultSelectionsService.getCleanItems(this.searchContainer));
    await this.operationService.setOperations(this.searchContainer.operationContainer, this.searchContainer);
  }

  private setOperationsCallback = () =>  {
    this.setOperations().then();
  }

  private async setOperationContainer() {
    this.searchContainer.operationContainer = await this.operationService.createOperationContainer(OperationTarget.SEARCH_VIEW);
    this.searchContainer.operationContainer.getTargetId = this.searchContainer.getTargetId;
    this.searchContainer.operationContainer.templateGroupId = this.searchContainer.templateGroupId;
    this.searchContainer.operationContainer.listFieldTemplateId = this.searchContainer.listFieldTemplateId;
    this.searchContainer.operationContainer.openOperationDialogFn = (callback: any) => {
      this.operationDialogService.openOperationDialog(this.searchContainer.operationContainer).then(data => {
        this.handleResultObject(data.resultObject);
        if (callback) {
          callback(data);
        }
      });
    };
    this.setSearchSelector();
    this.searchExecutorService.subscribeToSearchResult(this.searchContainer, this.setOperationsCallback);
    if (this.searchContainer.selections.selectItemCallback) {
      console.warn('Select item callback already set');
    }
    this.searchContainer.selections.selectItemCallback = () => {
      this.setOperations().then();
    };
  }

  private setSearchSelector() {
    this.searchContainer.operationContainer.enableSelector = (selector: Selector, params: SelectorCreationParams) => {
      this.searchContainer.operationContainer.contentInfo.setSelectorUsed(selector);
      this.searchSelectorService.enableSelector(selector, <SelectorContainer>this.searchContainer.operationContainer, params,
        {
          searchContainerCreated: () => {
          },
          selectorCallback: (selectedObj: any) => {
            this.searchSelectorService.disableSelector(<SelectorContainer>this.searchContainer.operationContainer);
            this.searchContainer.operationContainer.selectorCallback(selectedObj, selector, () => {
              if (this.searchContainer.operationContainer.currentOperation.$$currentStep.operation_step_type === OperationStepType.CLOSE_OPERATION_REFRESH) {
                this.searchResultSelectionsService.clearSelections(this.searchContainer);
                this.searchExecutorService.runSearch(this.searchContainer).then();
              } else {
                const resultObject = selectedObj[0];
                this.handleResultObject(resultObject).then();
              }
            });
          }
        }
      );
    };
  }

  isFolder: boolean = false;
}
