import {Component, Input, OnDestroy, OnInit, WritableSignal} from '@angular/core';
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {JobStatusItem} from "../../core/definitions/job-status-item";
import {LoginService} from "../../core/login.service";
import {UserData} from "../../core/definitions/user-data";
import {UserPrivilege} from "../../administration/admin-user/User";
import {IsActiveService} from "../../main-menu/jobstatus/is-active.service";
import {JobStatusSubscriberService} from "../../core/jobstatussubcriber/job-status-subscriber.service";
import {OperationExecutionStatus} from "../../core/definitions/operation-execution-status.enum";
import {OperationStep} from "../../core/definitions/operation-step";
import {OperationService} from "../../operations/operation.service";
import {JobStatusItemComponent} from "./job-status-item/job-status-item.component";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'app-job-status-v2',
  standalone: true,
  imports: [
    MatTabGroup,
    MatTab,
    TranslateModule,
    JobStatusItemComponent,
    MatIcon
  ],
  templateUrl: './job-status-v2.component.html',
  styleUrl: './job-status-v2.component.scss'
})
export class JobStatusV2Component implements OnDestroy, OnInit {
  @Input() activeJobs: WritableSignal<number>;

  activeJobTimer: NodeJS.Timeout = null;
  cachedSteps = {}
  currentItemAlert: any;
  currentUser: UserData;
  currentUserIsAdmin: boolean;
  finishedJob: JobStatusItem;
  jobSubscriber: any = null;
  jobSubscriberTimeout: NodeJS.Timeout = null;
  lastDisplayedQueueId: string = null;
  hasActiveJob: boolean;
  messages: JobStatusItem[];
  popupActive: boolean = false;
  showPopupTimeout: NodeJS.Timeout = null;

  constructor(
    private readonly isActiveService: IsActiveService,
    private readonly jobSubscriberService: JobStatusSubscriberService,
    private readonly loginService: LoginService,
    private readonly operationService: OperationService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.initialize();

    this.lastDisplayedQueueId = localStorage.getItem('lastDisplayedQueueId');

    this.loginService.currentUser.subscribe(user => {
      this.currentUser = user;
      this.currentUserIsAdmin = user["rights_level"] as UserPrivilege === UserPrivilege.ADMIN;
    });

    this.isActiveService.isActive.subscribe(state => {
      this.hasActiveJob = state;

      if (this.activeJobTimer === null && state) {
        this.activeJobTimer = setInterval(() => {
          this.hideActiveDialog();
        }, 5100);
      }
    });
  }

  ngOnDestroy() {
    if (this.activeJobTimer !== null) {
      clearTimeout(this.activeJobTimer);
    }
  }

  cancelItemDialog(itemId: string) {
    if (itemId) {
      this.lastDisplayedQueueId = itemId;
      localStorage.setItem('lastDisplayedQueueId', itemId);
    }
    if (this.showPopupTimeout && this.popupActive) {
      clearTimeout(this.showPopupTimeout);
      this.showPopupTimeout = null;
      this.popupActive = false;
      this.finishedJob = null;
    }
  }

  getMessages(currentUserOnly: boolean): JobStatusItem[] {
    if (this.messages !== undefined) {
      if (currentUserOnly) {
        return this.messages.filter(message => {
          return message.created_by_id.replace('USER-', '') ===
            this.currentUser.artifact_id.replace('USER-', '');
        })
      }
      else {
        return this.messages;
      }
    }
    else {
      return [];
    }
  }

  hideActiveDialog() {
    this.hasActiveJob = false;
  }

  private checkClearWaitingForMessages(jobStatusItems: JobStatusItem[]) {

  }

  private getStepSetJobStatusItemRef(jobStatusItem: JobStatusItem) {
    if (jobStatusItem.status === 'step complete') {
      const cachedKey = jobStatusItem.queue_id;
      const cachedStep = this.cachedSteps[cachedKey];
      if (!cachedStep) {
        this.operationService.getNextOperationStepFromQueue(jobStatusItem.queue_id).then(step => {
          this.cachedSteps[cachedKey] = step;
          this.setJobStatusItemRef(step, jobStatusItem);
        });
      } else {
        this.setJobStatusItemRef(cachedStep, jobStatusItem);
      }
    }
  }

  private initialize() {
    if (this.jobSubscriber !== null) {
      return;
    }

    clearTimeout(this.jobSubscriberTimeout);

    this.jobSubscriber = this.jobSubscriberService.parser.messages$.subscribe(messages => {
      this.setMessages(messages);
    })
  }

  private setFinishedJob = () => {
    let res = null;
    const m = this.jobSubscriberService.getFinished(this.messages, this.currentUserIsAdmin, this.currentUser);
    if (m !== null) {
      if (m.queue_id !== this.lastDisplayedQueueId &&
        m.created_by_id.replace('USER-', '') === this.currentUser?.artifact_id.replace('USER-', '')) {
        this.currentItemAlert = m.queue_id;
        this.isActiveService.changeState(false);
        res = m;
      }
    }
    this.finishedJob = res;

    const show = this.finishedJob;
    if (show) {
      // Cancel the popup automatically after 15 seconds,
      // avoid multiple timeouts if more tasks are finished
      // within the specified timeout-period.
      if (!this.showPopupTimeout) {
        this.popupActive = true;
        this.showPopupTimeout = setTimeout(() => {
          this.cancelItemDialog(this.currentItemAlert);
        }, 10000);
      }
    }
  }

  private setJobStatusItemRef(step: OperationStep, jobStatusItem: JobStatusItem) {
    if (step && step.change_state) {
      [jobStatusItem.ref, jobStatusItem.refParams] = this.operationService.getStateParams(
        step.change_state[0], jobStatusItem);
    }
  }

  private setJobTypeName(jobStatusItem: JobStatusItem) {
    if (jobStatusItem.task_type === 'reports') {
      jobStatusItem.task_name = 'TRANS__JOB_TYPE_NAME__REPORT';
    }
  }

  private setMessages(jobStatusItems: JobStatusItem[]) {
    this.checkClearWaitingForMessages(jobStatusItems);
    this.messages = jobStatusItems;

    this.activeJobs.set(0);

    for (const item of jobStatusItems) {
      this.setJobTypeName(item);
      this.setNameFromFiletype(item);
      this.jobSubscriberService.setStatusType(item);
      this.setStatusMessage(item);
      this.getStepSetJobStatusItemRef(item);
      this.setTaskMetadata(item);
    }


  }

  private setNameFromFiletype(jobStatusItem: JobStatusItem) {
    let fileTypeName: string = '';

    if (jobStatusItem.filetype) {
      switch (jobStatusItem.filetype) {
        case 'pdf':
          fileTypeName = ': PDF';
          break;

        case 'docx':
          fileTypeName = ': Word';
          break;

        case 'xls':
          fileTypeName = ': Excel';
          break;

        default:
          fileTypeName = ': Unknown';
      }
    }

    jobStatusItem.fileTypeName = fileTypeName;
  }

  private setStatusMessage(jobStatusItem: JobStatusItem) {
    let key: string;
    let extraData: string = null;

    const status: string = jobStatusItem.status;
    const percent: string = jobStatusItem.progress.toFixed(2);

    switch(status) {
      case ('download_pdf'):
        this.activeJobs.set(this.activeJobs() + 1);
        key = 'TRANS__JOB_STATUS__CREATING_PDF';
        extraData = `: ${percent}%`;
        break;

      case ('loading_artifact_events'):
        this.activeJobs.set(this.activeJobs() + 1);
        key = 'TRANS__JOB_STATUS__LOADING_OBJECT_DETAILS';
        extraData = `: ${percent}%`;
        break;

      case ('loading_data'):
        this.activeJobs.set(this.activeJobs() + 1);
        key = 'TRANS__JOB_STATUS__LOADING_OBJECTS';
        extraData = `: ${percent}%`;
        break;

      case ('parsing_objects'):
        this.activeJobs.set(this.activeJobs() + 1);
        key = 'TRANS__JOB_STATUS__PARSING_OBJECTS';
        extraData = `: ${percent}%`;
        break;

      case (jobStatusItem.status_message):
        this.activeJobs.set(this.activeJobs() + 1);
        key = jobStatusItem.status_message;
        break;

      case (OperationExecutionStatus.DOWNLOADED):
        key = 'TRANS__JOB_STATUS__DOWNLOADED';
        break;

      case (OperationExecutionStatus.FINISHED):
        key = 'TRANS__JOB_STATUS__FINISHED';
        break;

      case (OperationExecutionStatus.IN_PROGRESS):
        this.activeJobs.set(this.activeJobs() + 1);
        key = jobStatusItem.status_message;
        extraData = `: ${percent}%`;
        break;

      case(OperationExecutionStatus.QUEUED):
      case(OperationExecutionStatus.REGISTERED):
        this.activeJobs.set(this.activeJobs() + 1);
        key = 'TRANS__JOB_STATUS__QUEUED';
        break;

      default:
        key = status;
        break;
    }

    jobStatusItem.statusMessage = this.translate.instant(key) + (extraData ? extraData : '');
  }

  private setTaskMetadata(jobStatusItem: JobStatusItem) {
    const label = this.translate.instant('TRANS__JOB_STATUS__CREATED_BY');
    const registered = jobStatusItem.registered;
    const fullName = jobStatusItem.fullname;

    if (fullName !== undefined && fullName !== '') {
      jobStatusItem.taskMetaData = `${label}: ${fullName} - ${registered}`;
    }
    else {
      jobStatusItem.taskMetaData = `${label}: ${registered}`;
    }
  }
}
