import {OperationTarget} from "../../../core/definitions/operation-target.enum";
import {OperationExecutorType} from "../../../core/definitions/operation-executor-type.enum";
import {OperationStepType} from "../../../core/definitions/operation-step-type.enum";
import {OperationStepExecutionParams} from "../../../core/definitions/operation-step-execution-params";
import {OperationExecutionStatus} from "../../../core/definitions/operation-execution-status.enum";
import {ReportUserGenerated} from "../../../core/ModelStore/models/ReportUserGenerated";
import {OperationService} from "../../../operations/operation.service";
import {CmsApiService} from "../../../core/cms-api.service";

export async function generateReport(generatedReport: ReportUserGenerated, localStorageItem: any, operationService: OperationService, cms: CmsApiService) {
  let params = {
    localStorageItem: null,
    reportId: generatedReport.id,
    accessToken: localStorage.getItem('kit.access_token'),
    idToken: localStorage.getItem('kit.id_token')
  }

  let parsedItem: any = {};

  if (localStorageItem) {
    try {
      parsedItem = JSON.parse(localStorageItem);
    } catch (e) {
      console.error('Error parsing localStorageItem:', e);
    }
  }

  params.localStorageItem = {
    name: parsedItem.name || '',
    shortName: parsedItem.shortName || '',
    api: parsedItem.api || '',
    id: parsedItem.id || '',
    online: parsedItem.online || false,
  }

  const operationContainer = await operationService.createOperationContainer(
    OperationTarget.CREATE_REPORT_PDF, []);
  await operationService.setOperations(operationContainer);
  await operationService.setCurrentOperationByType(
    operationContainer, OperationExecutorType.REPORTS_V2, OperationStepType.GENERATE_REPORT_PDF);

  let operationStepParams = new OperationStepExecutionParams();
  operationStepParams.operation_objects = [params];
  operationStepParams.operation_type_id = "6f6f6c0b-6a5d-4b6b-9e3a-3e1b8f5f5f5f";
  operationStepParams.operation_step_index = 0;

  const res = await cms.executeOperationStep(operationStepParams);
  if (res.status === OperationExecutionStatus.QUEUED) {
    operationService.handleStepResultQueued(operationContainer, res);
  }
}
