import {Model} from "../Model";

export const bilderapportMedOversiktsfelterUuid  = 'ct_203-122c2a45-fe7a-4a2c-814a-fd5dc6917250';
export const katalograpportMedOversiktsfelterUuid = 'ct_203-3772e49f-05f7-41c9-8bc1-b143663c4e7a';
export const rapportlisteMedBildeUuid   = 'ct_203-7c44c83b-9329-45bf-90ee-0fc5e35e8ea8';
export const bilderapportId = 'ct_203-7b5035be-a3e1-4712-9de5-e5beb7279680';
export const prosedyrerapportUuid = 'ct_203-722c41c1-ff23-4901-af8e-39ab51aaba39';
export const hendelsesrapportUuid = 'ct_203-33444d86-e4af-497e-a5de-ce1ff9c66e98';

export class ReportViewType implements Model {
  id: string;
  loading: Promise<Model> | undefined;
  status: string;
  name: string;
  viewChoices: string;
  reportViewType: string;
  templateCode: string;
  templateCodePerObject: string;
}
