import {Component, OnInit} from "@angular/core";
import {CmsApiService} from "../../../core/cms-api.service";
import {ModelStore} from "../../../core/ModelStore/ModelStore";
import {ReportUserGenerated} from "../../../core/ModelStore/models/ReportUserGenerated";
import {ModelCacheArray} from "../../../core/ModelStore/ModelCacheArray";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {UrlQueryParam} from "../../../core/ModelStore/UrlQueryParam";

@Component({
  selector: 'report-generated',
  templateUrl: './report-draft.component.html',
  styleUrls: ['./report-draft.component.scss']
})
export class ReportDraftComponent implements OnInit {
  generatedReports: ModelCacheArray<ReportUserGenerated>;
  filteredReports: ReportUserGenerated[];
  selectedReports: string[] = [];
  searchText: string;

  constructor(private readonly cms: CmsApiService,
              private modelStore: ModelStore) {
    // Bind the methods to ensure correct context
    this.deleteReports = this.deleteReports.bind(this);

  }

  deleteReports() {
    console.log('DELETE REPORT: ', this.selectedReports);
  }

  async ngOnInit(): Promise<void> {
    this.generatedReports = this.modelStore.findAllModels('report_user_generated', new UrlQueryParam('timezone_offset', '' + new Date().getTimezoneOffset()));
    await this.generatedReports.loading;
    this.filterReports()

    let reportViewTypes = this.modelStore.findAllModels('report_view_type');
    await reportViewTypes.loading;

    if (this.generatedReports.length > 0) {
      this.generatedReports.forEach(async report => {
        if (report.reportViewTypeId) {
          report.reportViewType = await this.modelStore.findModel('report_view_type', report.reportViewTypeId);
        }
      });
    }
  }

  reportSelectionChanged(event: MatCheckboxChange, templateId : string) {
    console.log('REPORT SELECTION CHANGED: ', event, templateId);
    if (event.checked && !this.selectedReports.includes(templateId)) {
      this.selectedReports.push(templateId);
    } else if (!event.checked && this.selectedReports.includes(templateId)) {
      this.selectedReports.splice(this.selectedReports.indexOf(templateId), 1);
    }
  }

  filterReports() {
    const searchTextLower = this.searchText ? this.searchText.toLowerCase() : '';

    this.filteredReports = this.generatedReports.filter(report => {
      const name = report.name ? report.name.toLowerCase() : '';
      const shortDescription = report.shortDescription ? report.shortDescription.toLowerCase() : '';
      const reportType : string = report.reportViewType?.name ? report.reportViewType.name.toLowerCase() : '';

      return report.reportStatus !== 'deleted' && report.reportStatus !== 'generated' && (name.includes(searchTextLower) || shortDescription.includes(searchTextLower) || reportType.includes(searchTextLower));
    });
  }
}
