import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {JobStatusSubscriberService} from '../../core/jobstatussubcriber/job-status-subscriber.service';
import {IsActiveService} from './is-active.service';
import {TranslateService} from '@ngx-translate/core';
import {FileSaverService} from 'ngx-filesaver';
import {JobStatusItem} from '../../core/definitions/job-status-item';
import {OperationService} from '../../operations/operation.service';
import {OperationStep} from '../../core/definitions/operation-step';
import {OperationExecutionStatus} from '../../core/definitions/operation-execution-status.enum';
import {LoginService} from 'src/app/core/login.service';
import {UserPrivilege} from 'src/app/administration/admin-user/User';
import {CmsApiService} from '../../core/cms-api.service';
import {PrimusStateMapperService} from '../../core/primus-state-mapper.service';
import {LoggerService} from '../../core/logger.service';


@Component({
  selector: 'app-job-statuses',
  templateUrl: './job-status.component.html',
  styleUrls: ['./job-status.component.scss']
})
export class JobStatusComponent implements OnInit, OnChanges, OnDestroy {

  lastStatusIcon: string;
  clicked: boolean;
  finishedJob: JobStatusItem;
  @Input() closeMenu: boolean;

  private lastDisplayedQueueIdKey = 'lastDisplayedQueueId';
  private messages: JobStatusItem[];
  private lastDisplayedQueueId: string = null;
  private currentItemAlert: string = null;
  private isJobActive = false;
  private activeJobDialogTimer = null;
  private jobSubscriber = null;
  private jobSubscriberTimeoutID = null;
  private cachedSteps = {};
  public adminUser: boolean;
  private currentUser: any;

  private showPopupTimeoutID = null;
  private popupTimeoutMS = 10000;
  private popupActive = false;
  private newMessagesSet = false;

  constructor(
    private logger: LoggerService,
    public primusStateMapper: PrimusStateMapperService,
    private isActiveService: IsActiveService,
    private jobStatusSubscriberService: JobStatusSubscriberService,
    private translate: TranslateService,
    private fileSaverService: FileSaverService,
    private operationService: OperationService,
    private loginService: LoginService,
    private cms: CmsApiService
  ) {
  }

  ngOnInit() {
    this.initJobSubscriber();
    this.lastDisplayedQueueId = localStorage.getItem(this.lastDisplayedQueueIdKey);

    this.loginService.currentUser.subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.adminUser = user['rights_level'] as UserPrivilege === UserPrivilege.ADMIN;
      }
    });

    this.isActiveService.isActive.subscribe(state => {
      this.isJobActive = state;
      if (this.activeJobDialogTimer === null && state) {
        // Display the active job dialog for 5 seconds before hiding it automatically.
        this.activeJobDialogTimer = setInterval(() => {
          this.hideActiveDialog();
        }, 5000);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.closeMenu.currentValue) {
      this.clicked = false;
    }
  }

  ngOnDestroy(): void {
    if (this.showPopupTimeoutID) {
      clearTimeout(this.showPopupTimeoutID);
    }
    if (this.activeJobDialogTimer) {
      clearInterval(this.activeJobDialogTimer);
    }
  }

  /**
   * Initializes job status subscription.
   */
  initJobSubscriber = () => {
    if (this.jobSubscriber !== null) {
      return;
    }
    window.clearTimeout(this.jobSubscriberTimeoutID);
    this.jobSubscriber = this.jobStatusSubscriberService.parser.messages$
      .subscribe(m => {
        this.setMessages(m);
      });
  }

  /**
   * Cancels the job status subscription temporarily, before resubscribing.
   * Used when the user clicks on the panel next to a report in order to make it stay
   * open and not close automatically every time the data has been fetched from the server.
   *
   * NOTE:
   * Re-subscription happens after 10 seconds of inactivity or when the user closes the panel
   * by clicking on it.
   */
  temporaryCancelJobSubscriber = () => {
    this.jobSubscriber.unsubscribe();
    this.jobSubscriber = null;
    this.jobSubscriberTimeoutID = setTimeout(() => {
      this.initJobSubscriber();
    }, 10000);
  }

  getShowActiveDialog() {
    return this.isJobActive;
  }

  hideActiveDialog() {
    this.isActiveService.changeState(false);
  }

  // getNumMessages() {
  //   if (this.messages) {
  //     return this.messages.length;
  //   } else {
  //     return 0;
  //   }
  // }

  /**
   * Gets the list of jobs.
   */
  getMessages(currentUserOnly: boolean): JobStatusItem[] {
    if (typeof (this.messages) !== 'undefined') {
      if (currentUserOnly) {
        return this.messages.filter(m => {
          return m.created_by_id.replace('USER-', '') ===
            this.currentUser.artifact_id.replace('USER-', '');
        });
      } else {
        return this.messages;
      }
    } else {
      return [];
    }
  }

  /**
   * Toggles the job status panel in the top bar on or off.
   */
  togglePanel() {
    this.clicked = !this.clicked;
    this.hideActiveDialog();
    this.cancelItemDialog(this.currentItemAlert);
  }

  /**
   * Cancels the dialog which appears immediately after a job has finished.
   */
  cancelItemDialog(itemId: string) {
    if (itemId) {
      this.lastDisplayedQueueId = itemId;
      localStorage.setItem(this.lastDisplayedQueueIdKey, itemId);
    }
    if (this.showPopupTimeoutID && this.popupActive) {
      clearTimeout(this.showPopupTimeoutID);
      this.showPopupTimeoutID = null;
      this.popupActive = false;
      this.finishedJob = null;
    }
  }

  showError(jobStatusItem: JobStatusItem) {
    // TODO: make this appear inside a dialog instead!!!
    if (jobStatusItem.error_message) {
      alert(jobStatusItem.error_message);
    } else {
      alert(jobStatusItem.status_message);
    }
  }

  /**
   * Downloads the requested report to the client.
   * @param reportId
   * @param fileType
   */
  downloadReport = (reportId: string, fileType: string) => {
    this.lastDisplayedQueueId = null;  // Reset current finished job info.
    this.cms.downloadReport(reportId).subscribe(
      blob => {
        this.fileSaverService.save(blob, reportId + '.' + fileType);
        this.cancelItemDialog(reportId);
      },
      error => {
        this.logger.error('Error downloading report', error);
        this.cancelItemDialog(reportId);
      });
  }

  // /**
  //  * Returns the mime-type that corresponds to the specified filetype.
  //  * @param fileType string Type of file
  //  */
  // private getMimeTypeFromFileType(fileType: string) {
  //   switch (fileType) {
  //     case 'pdf':
  //       return 'application/pdf';
  //     case 'docx':
  //       return ' application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  //     case 'xls':
  //       return 'application/vnd.ms-excel';
  //   }
  // }

  /**
   * Shows the dialog which appears immediately after a job has finished.
   */
  private setFinishedJob = () => {
    let res = null;
    const m = this.jobStatusSubscriberService.getFinished(this.messages, this.adminUser, this.currentUser);
    if (m !== null) {
      if (m.queue_id !== this.lastDisplayedQueueId &&
        m.created_by_id.replace('USER-', '') === this.currentUser?.artifact_id.replace('USER-', '')) {
        this.currentItemAlert = m.queue_id;
        this.isActiveService.changeState(false);
        res = m;
      }
    }
    this.finishedJob = res;

    const show = this.finishedJob && !this.clicked;
    if (show) {
      // Cancel the popup automatically after 15 seconds,
      // avoid multiple timeouts if more tasks are finished
      // within the specified timeout-period.
      if (!this.showPopupTimeoutID) {
        this.popupActive = true;
        this.showPopupTimeoutID = setTimeout(() => {
          this.cancelItemDialog(this.currentItemAlert);
        }, this.popupTimeoutMS);
      }
    }
  }

  /**
   * Method used to decide which status icon to be displayed in the top bar.
   */
  private setLastStatusIcon() {
    const activeJobs = this.hasActiveJobs(this.messages) || this.isJobActive;
    const failedJobs = this._hasFailedJobs();
    const finishedJobs = this.jobStatusSubscriberService.hasFinishedJobs(
      this.messages, this.adminUser, this.currentUser);
    const downloaded = this._isDownloaded();

    let state = '';

    if (activeJobs) {
      state = 'active';
    } else if (failedJobs) {
      state = 'failed';
    } else if (finishedJobs) {
      state = 'finished';
    }

    if (activeJobs && !failedJobs && !finishedJobs && !downloaded) {
      state = 'active';
    }
    this.lastStatusIcon = state;
  }

  private setTaskMetaData(jobStatusItem: JobStatusItem) {
    const label = this.translate.instant('TRANS__JOB_STATUS__CREATED_BY');
    const registered = jobStatusItem.registered;
    const fullname = jobStatusItem.fullname;
    if (typeof fullname !== 'undefined' && fullname !== '') {
      jobStatusItem.taskMetaData = label + ': ' + fullname + ' - ' + registered;
    } else {
      jobStatusItem.taskMetaData = label + ': ' + registered;
    }
  }

  /**
   * Gets the correct job name based on the specified task type.
   * @param jobStatusItem
   */
  private setJobTypename(jobStatusItem: JobStatusItem) {
    if (jobStatusItem.task_type === 'reports') {
      jobStatusItem.task_name = 'TRANS__JOB_TYPE_NAME__REPORT';
    }
    // TODO: add support for other task types here.
  }

  private setNameFromFiletype(jobStatusItem: JobStatusItem) {
    let res = '';
    if (jobStatusItem.filetype) {
      switch (jobStatusItem.filetype) {
        case 'pdf':
          res = ': PDF';
          break;
        case 'docx':
          res = ': Word';
          break;
        case 'xls':
          res = ': Excel';
          break;
        default:
          res = ': Unknown';
      }
    }
    jobStatusItem.fileTypeName = res;
  }

  private setStatusMessage(jobStatusItem: JobStatusItem) {
    const s = jobStatusItem.status;
    const percent = jobStatusItem.progress.toFixed(2);
    let res: string;

    if (s === OperationExecutionStatus.REGISTERED || s === OperationExecutionStatus.QUEUED) {
      res = this.translate.instant('TRANS__JOB_STATUS__QUEUED');
    } else if (s === OperationExecutionStatus.IN_PROGRESS) {
      res = this.translate.instant(jobStatusItem.status_message) + ': ' + percent + '%';
    } else if (s === OperationExecutionStatus.DOWNLOADED) {
      res = this.translate.instant('TRANS__JOB_STATUS__DOWNLOADED');
    } else if (s === 'loading_data') {
      res = this.translate.instant('TRANS__JOB_STATUS__LOADING_OBJECTS') + ': ' + percent + '%';
    } else if (s === 'loading_artifact_events') {
      res = this.translate.instant('TRANS__JOB_STATUS__LOADING_OBJECT_DETAILS') + ': ' + percent + '%';
    } else if (s === 'parsing_objects') {
      res = this.translate.instant('TRANS__JOB_STATUS__PARSING_OBJECTS') + ':' + percent + '%';
    } else if (s === OperationExecutionStatus.FINISHED) {
      res = this.translate.instant('TRANS__JOB_STATUS__FINISHED');
    } else if (s === 'download_pdf') {
      res = this.translate.instant('TRANS__JOB_STATUS__CREATING_PDF') + ':' + percent + '%';
    } else if (jobStatusItem.status_message) {
      res = this.translate.instant(jobStatusItem.status_message);
    } else {
      res = s;
    }
    jobStatusItem.statusMessage = res;
  }

  /**
   * True if jobs with the status "registered" or "in_progress" exists.
   * @private
   */
  private hasActiveJobs(messages: JobStatusItem[]) {
    return this.jobStatusSubscriberService.hasStatus(
      [
        OperationExecutionStatus.IN_PROGRESS,
        OperationExecutionStatus.QUEUED,
        OperationExecutionStatus.REGISTERED,
        'loading_data',
        'download_pdf',
        'loading_artifact_events',
        'parsing_objects'],
      messages,
      this.adminUser,
      this.currentUser
    ) !== null;
  }

  /**
   * True if jobs with the status "failed" exists.
   * @private
   */
  private _hasFailedJobs() {
    return this.jobStatusSubscriberService.hasStatus(
      [OperationExecutionStatus.FAILED],
      this.messages,
      this.adminUser,
      this.currentUser) !== null;
  }

  private _isDownloaded() {
    return this.jobStatusSubscriberService.hasStatus(
      [OperationExecutionStatus.DOWNLOADED],
      this.messages,
      this.adminUser,
      this.currentUser) !== null;
  }

  private setMessages(jobStatusItems: JobStatusItem[]) {
    this.checkClearWaitingForMessages(jobStatusItems);
    this.messages = jobStatusItems;
    this.setLastStatusIcon();
    jobStatusItems.forEach(jobStatusItem => {
      this.setJobTypename(jobStatusItem);
      this.setNameFromFiletype(jobStatusItem);
      this.setTaskMetaData(jobStatusItem);
      this.jobStatusSubscriberService.setStatusType(jobStatusItem);
      this.setStatusMessage(jobStatusItem);
      this.getStepSetJobStatusItemRef(jobStatusItem);
    });
    this.setFinishedJob();
  }

  private checkClearWaitingForMessages(jobStatusItems: JobStatusItem[]) {
    if (this.jobStatusSubscriberService.isWaitingForMessages()) {
      this.checkSetNewMessagesFlag(jobStatusItems);
      if (this.newMessagesSet && !this.hasActiveJobs(jobStatusItems)) {
        this.jobStatusSubscriberService.setWaitForMessages(false)
        this.newMessagesSet = false;
      }
    }
  }

  private checkSetNewMessagesFlag(jobStatusItems: JobStatusItem[]) {
    if (this.newMessagesSet) {
      return;
    }
    if (jobStatusItems?.length && (!this.messages?.length || this.messages.length !== jobStatusItems.length)) {
      this.newMessagesSet = true;
    } else if (jobStatusItems.length === this.messages.length) {
      for (let idx = 0 ; idx < jobStatusItems.length ; idx++) {
        if (jobStatusItems[idx].queue_id !== this.messages[idx].queue_id) {
          this.newMessagesSet = true;
          break;
        }
      }
    }
  }

  private getStepSetJobStatusItemRef(jobStatusItem: JobStatusItem) {
    if (jobStatusItem.status === 'step complete') {
      const cachedKey = jobStatusItem.queue_id;
      const cachedStep = this.cachedSteps[cachedKey];
      if (!cachedStep) {
        this.operationService.getNextOperationStepFromQueue(jobStatusItem.queue_id).then(step => {
          this.cachedSteps[cachedKey] = step;
          this.setJobStatusItemRef(step, jobStatusItem);
        });
      } else {
        this.setJobStatusItemRef(cachedStep, jobStatusItem);
      }
    }
  }

  private setJobStatusItemRef(step: OperationStep, jobStatusItem: JobStatusItem) {
    if (step && step.change_state) {
      [jobStatusItem.ref, jobStatusItem.refParams] = this.operationService.getStateParams(
        step.change_state[0], jobStatusItem);
    }
  }
}
