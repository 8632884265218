import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminSettingsComponent} from './admin-settings/admin-settings.component';
import {AdminConceptListsComponent} from './admin-concept-lists/admin-concept-lists.component';
// eslint-disable-next-line max-len
import {AdminListActionToolbarComponent} from './admin-lists/admin-list-action-toolbar/admin-list-action-toolbar.component';
import {AdminListComponent} from './admin-lists/admin-list/admin-list.component';
// eslint-disable-next-line max-len
import {AdminListFilterMenuComponent} from './admin-lists/admin-list-filter-menu/admin-list-filter-menu.component';
// eslint-disable-next-line max-len
import {AdminListQueryComponent} from './admin-lists/admin-list-query/admin-list-query.component';
import {AdminEditItemComponent} from './admin-lists/admin-edit-item/admin-edit-item.component';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {SharedModule} from '../shared/shared.module';
import {AdminListTableComponent} from './admin-lists/admin-list-table/admin-list-table.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ObjectViewModule} from '../object-view/object-view.module';
import {MatCardModule} from '@angular/material/card';
import {AdminSettingsSettingComponent} from './admin-settings/admin-settings-setting/admin-settings-setting.component';
import {AdminSettingsFieldValueComponent} from './admin-settings/admin-settings-field-value/admin-settings-field-value.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {ObjectEditModule} from '../object-edit/object-edit.module';
import {AdminSettingsFaqComponent} from './admin-settings/admin-settings-faq/admin-settings-faq.component';
import {MatExpansionModule} from '@angular/material/expansion';
// eslint-disable-next-line max-len
import {AdminListItemActionComponent} from './admin-lists/admin-list-item-action/admin-list-item-action.component';
import {MatIconModule} from '@angular/material/icon';
import {AdminListItemSpecialValueComponent} from './admin-lists/admin-list-item-special-value/admin-list-item-special-value.component';
import {AdminListSettingsComponent} from './admin-lists/admin-list-settings/admin-list-settings.component';
import {AdminActionListMenuComponent} from './admin-lists/admin-list-action-menu/admin-action-list-menu.component';
import {AdminIdFormatComponent} from './admin-id-format/admin-id-format.component';
import {MatTabsModule} from '@angular/material/tabs';
import {AdminIdFormatGlobalViewComponent} from './admin-id-format/admin-id-format-global-view/admin-id-format-global-view.component';
import {AdminIdFormatCollectionViewComponent} from './admin-id-format/admin-id-format-collection-view/admin-id-format-collection-view.component';
import {AdminIdFormatFilterComponent} from './admin-id-format/admin-id-format-edit-section/admin-id-format-filter/admin-id-format-filter.component';
import {AdminIdFormatCollectionListComponent} from './admin-id-format/admin-id-format-collection-list/admin-id-format-collection-list.component';
import {AdminIdFormatEditSectionComponent} from './admin-id-format/admin-id-format-edit-section/admin-id-format-edit-section.component';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatListModule} from '@angular/material/list';
import {AdminIdFormatEditComponent} from './admin-id-format/admin-id-format-edit/admin-id-format-edit.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {AdminUsersProfilePageComponent} from './admin-user/admin-users-profile-page/admin-users-profile-page.component';
import {MatChipsModule} from '@angular/material/chips';
import { UserDeletedDialogComponent } from './admin-user/admin-users-profile-page/user-deleted-dialog/user-deleted-dialog.component';
import { UserProfileSectionComponent } from './admin-user/admin-users-profile-page/user-profile-section/user-profile-section.component';
import { UserInfoSectionComponent } from './admin-user/admin-users-profile-page/user-info-section/user-info-section.component';
import { AccessRightsSectionComponent } from './admin-user/admin-users-profile-page/access-rights-section/access-rights-section.component';
import { ProfileImageUploaderComponent } from './admin-user/admin-users-profile-page/profile-image-uploader/profile-image-uploader.component';
import {MediaSelectorModule} from '../media-selector/media-selector.module';
import {MatDialogModule} from '@angular/material/dialog';
import { AdminLocationComponent } from './admin-location-place/admin-location/admin-location.component';
import { AdminLocationFilterComponent } from './admin-location-place/admin-location/admin-location-filter/admin-location-filter.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AdminLocationPlaceFormModalComponent } from './admin-location-place/admin-location-place-form-modal/admin-location-place-form-modal.component';
import { AdminConceptJoinConceptsComponent } from './admin-concept-lists/admin-concept-join-concepts/admin-concept-join-concepts.component';
import { AdminConceptQueryConceptTypesComponent } from './admin-concept-lists/admin-concept-query-concept-types/admin-concept-query-concept-types.component';
import { AdminConceptObjectFilterMenuComponent } from './admin-concept-lists/admin-concept-object-filter-menu/admin-concept-object-filter-menu.component';
import {MatTreeModule} from '@angular/material/tree';
import { AdminConceptConceptListSelectorComponent } from './admin-concept-lists/admin-concept-concept-list-selector/admin-concept-concept-list-selector.component';
import {AdminMatPaginatorIntl} from './admin-mat-paginator-intl';
import {AdminMainPageComponent} from './admin-main-page/admin-main-page.component';
import {AdminCreateNewComponent} from './admin-create-new/admin-create-new.component';
import {FaqPageComponent} from './faq-page/faq-page.component';
import {AboutPrimusComponent} from './about-primus/about-primus.component';
import {AdminTemplateGroupComponent} from './admin-template-group/admin-template-group.component';
import {AdminTemplateModelComponent} from './admin-template-model/admin-template-model.component';
import {ObjectSearchModule} from '../object-search/object-search.module';
import {AdminPlaceComponent} from './admin-location-place/admin-place/admin-place.component';
import {AdminPlaceFilterComponent} from './admin-location-place/admin-place/admin-place-filter/admin-place-filter.component';
import { AdminCollectionComponent } from './admin-collection/admin-collection.component';
import {RouterModule} from '@angular/router';
import { AdminAgentComponent } from './admin-agent/admin-agent.component';
import {OperationsModule} from '../operations/operations.module';
import { AdminNamedObjectComponent } from './admin-named-object/admin-named-object.component';
import { UserCollectionsComponent } from './admin-user/admin-users-profile-page/user-collections/user-collections.component';
import { UserCollectionsAdderComponent } from './admin-user/admin-users-profile-page/user-collections-adder/user-collections-adder.component';
import { UserCollectionRightsTypeSetterComponent } from './admin-user/admin-users-profile-page/user-collection-rights-type-setter/user-collection-rights-type-setter.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { AdminServiceComponent } from './admin-service/admin-service.component';
import { AdminServiceFieldsComponent } from './admin-service/admin-service-fields/admin-service-fields.component';
import { AdminServiceApiComponent } from './admin-service/admin-service-api/admin-service-api.component';
import { AdminServiceIndexComponent } from './admin-service/admin-service-index/admin-service-index.component';
import { AdminMassMediaImportComponent } from './admin-mass-media-import/admin-mass-media-import.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { AdminServiceImportComponent } from './admin-service/admin-service-import/admin-service-import.component';
import { SelectFieldDialogComponent } from './admin-service/admin-service-import/select-field-dialog/select-field-dialog.component';
import { EditImportFieldDialogComponent } from './admin-service/admin-service-import/edit-import-field-dialog/edit-import-field-dialog.component';
import { ConceptSelectComponent } from './admin-service/admin-service-import/concept-select/concept-select.component';
import { ImportFieldSelectComponent } from './admin-service/admin-service-import/import-field-select/import-field-select.component';
import { ColumnSelectComponent } from './admin-service/admin-service-import/column-select/column-select.component';
import {AdminAddressComponent} from "./admin-address/admin-address.component";
import {ReportsIndexComponent} from "./reports/index/reports-index.component";
import {ReportTemplatesComponent} from "./reports/report-templates/report-templates.component";
import {ReportOverviewComponent} from "./reports/report-overview/report-overview.component";
import {ReportStandardComponent} from "./reports/report-standard/report-standard.component";
import {ReportGeneratedComponent} from "./reports/report-generated/report-generated.component";
import {ViewGeneratedReportComponent} from "./reports/view-generated-report/view-generated-report.component";
import {
  EditReportTemplateComponent
} from "./reports/report-templates/edit-report-template/edit-report-template.component";
import {DragulaModule} from "ng2-dragula";
import {ReportPreviewComponent} from "./reports/report-preview/report-preview.component";
import {
  ReportPreviewCatalogueComponent
} from "./reports/report-preview/report-preview-catalogue/report-preview-catalogue.component";
import {EditReportComponent} from "./reports/report-generated/edit-report/edit-report.component";
import {
  ReportPictureWithOverviewComponent
} from "./reports/report-preview/report-picture-with-overview/report-picture-with-overview.component";
import {ReportImageComponent} from "./reports/report-preview/report-image/report-image.component";
import {ReportListComponent} from "./reports/report-preview/report-list/report-list.component";
import {AdminSelectionActionMenuComponent} from "./admin-selection-action-menu/admin-selection-action-menu.component";
import {ReportDraftComponent} from "./reports/report-draft/report-draft.component";
import {
  AdminSelectionActionComponent
} from "./admin-selection-action-menu/admin-selection-action/admin-selection-action.component";

@NgModule({
  declarations: [
    AdminMainPageComponent,
    AdminCreateNewComponent,
    AdminSelectionActionMenuComponent,
    AdminSelectionActionComponent,
    ReportsIndexComponent,
    ReportTemplatesComponent,
    ReportOverviewComponent,
    ReportStandardComponent,
    ReportGeneratedComponent,
    ReportDraftComponent,
    EditReportTemplateComponent,
    EditReportComponent,
    ReportPreviewComponent,
    ReportPreviewCatalogueComponent,
    ReportPictureWithOverviewComponent,
    ReportImageComponent,
    ReportListComponent,
    ViewGeneratedReportComponent,
    FaqPageComponent,
    AdminSettingsComponent,
    AdminConceptListsComponent,
    AdminListActionToolbarComponent,
    AdminListComponent,
    AdminListFilterMenuComponent,
    AdminListTableComponent,
    AdminListQueryComponent,
    AdminEditItemComponent,
    AdminSettingsSettingComponent,
    AdminSettingsFieldValueComponent,
    AdminSettingsFaqComponent,
    AdminListItemActionComponent,
    AdminListItemSpecialValueComponent,
    AdminListSettingsComponent,
    AdminActionListMenuComponent,
    AdminIdFormatComponent,
    AdminIdFormatGlobalViewComponent,
    AdminIdFormatCollectionViewComponent,
    AdminIdFormatFilterComponent,
    AdminIdFormatCollectionListComponent,
    AdminIdFormatEditSectionComponent,
    AdminIdFormatEditComponent,
    AdminUsersProfilePageComponent,
    UserDeletedDialogComponent,
    UserProfileSectionComponent,
    UserInfoSectionComponent,
    AccessRightsSectionComponent,
    ProfileImageUploaderComponent,
    AdminLocationComponent,
    AdminLocationFilterComponent,
    AdminLocationPlaceFormModalComponent,
    AdminPlaceComponent,
    AdminPlaceFilterComponent,
    AdminConceptJoinConceptsComponent,
    AdminConceptQueryConceptTypesComponent,
    AdminConceptObjectFilterMenuComponent,
    AdminConceptConceptListSelectorComponent,
    AboutPrimusComponent,
    AdminTemplateGroupComponent,
    AdminTemplateModelComponent,
    AdminCollectionComponent,
    AdminAgentComponent,
    AdminNamedObjectComponent,
    AdminAddressComponent,
    UserCollectionsComponent,
    UserCollectionsAdderComponent,
    UserCollectionRightsTypeSetterComponent,
    AdminServiceComponent,
    AdminServiceFieldsComponent,
    AdminServiceApiComponent,
    AdminServiceIndexComponent,
    AdminMassMediaImportComponent,
    AdminServiceImportComponent,
    SelectFieldDialogComponent,
    EditImportFieldDialogComponent,
    ConceptSelectComponent,
    ImportFieldSelectComponent,
    ColumnSelectComponent
  ],
  imports: [
    FormsModule,
    MatTableModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatCheckboxModule,
    MatRadioModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    CommonModule,
    SharedModule,
    ObjectViewModule,
    ObjectEditModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    MatListModule,
    ScrollingModule,
    MatChipsModule,
    MediaSelectorModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatTreeModule,
    ObjectSearchModule,
    RouterModule,
    OperationsModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    DragulaModule,
  ],
    exports: [
        ReportPreviewComponent,
        AdminListComponent
    ],
  providers: [
    {provide: MatPaginatorIntl, useClass: AdminMatPaginatorIntl}
  ]
})
export class AdministrationModule {
}
