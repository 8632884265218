import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Renderer2,
  ElementRef,
  ViewChild,
  AfterViewChecked,
  ChangeDetectorRef, OnInit
} from '@angular/core';
import {ReportUserGenerated} from "../../../../core/ModelStore/models/ReportUserGenerated";
import {MediaHelperService} from "../../../../core/media-helper.service";

@Component({
  selector: 'report-preview-catalogue',
  templateUrl: './report-preview-catalogue.component.html',
  styleUrls: ['./report-preview-catalogue.component.scss']
})
export class ReportPreviewCatalogueComponent implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild('reportContainer', { static: false }) reportContainer: ElementRef;
  @Input() reportTemplate: ReportUserGenerated;
  @Input() extractedArtifacts: any;
  @Input() viewOptionsCheckboxValues: any;
  @Input() preview: boolean = false;

  private inputsSet = false;
  private viewInitialized = false;
  private defaultReportViewOptions : { [key: string]: any } = { "vis_ledetekster": true, "høyrejuster_bilder": true, "ledetekst_og_verdi_på_en_linje": true, "vis_seksjonstitler": true};
  imageUrls : Map<string, string> = new Map();


  constructor(private renderer: Renderer2,
              private cdRef: ChangeDetectorRef,
              private mediaService: MediaHelperService) {

  }

  ngOnInit(): void {
    //If Report View Options is NULL, select default values, if empy string, leave empty
    if (this.reportTemplate.reportViewOptions === null) {
      this.reportTemplate.reportViewOptions = this.defaultReportViewOptions;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reportTemplate || changes.extractedArtifacts) {
      if (this.extractedArtifacts && this.extractedArtifacts.length > 0) {
        let emptyImageIds = [];

        this.extractedArtifacts.forEach(artifact => {
          const imageIds = artifact.images.map(image => image.image_id);
          imageIds.forEach(imageId => {
            if (!this.imageUrls.has(imageId)) {
              emptyImageIds.push(imageId);
            }
          });
        });

        console.log('fetching medium image URL for: ', emptyImageIds);

        if (emptyImageIds.length > 0) {
          this.mediaService.getImageUrlsFromImageIds(emptyImageIds, ['medium']).then((urls) => {
            const keys = Object.keys(urls);
            keys.forEach((key) => {
              this.imageUrls.set(key, urls[key]['medium']);
            });
          });
        }
      }

      const previousFieldSections = changes.reportTemplate?.previousValue?.fieldSections;
      const currentFieldSections = changes.reportTemplate?.currentValue?.fieldSections;

      const previousIsSelectedValues = previousFieldSections ? previousFieldSections.map(section => section.isSelected).join(', ') : '';
      const currentIsSelectedValues = currentFieldSections ? currentFieldSections.map(section => section.isSelected).join(', ') : '';

      console.log('PREVIOUS IS SELECTED VALUES: ', previousIsSelectedValues);
      console.log('CURRENT IS SELECTED VALUES: ', currentIsSelectedValues);

      this.cdRef.detectChanges();

      if (previousIsSelectedValues !== currentIsSelectedValues) {
        console.log('IS SELECTED VALUES HAVE CHANGED');
        // The isSelected values have changed
        this.cdRef.detectChanges();
      }

      this.inputsSet = this.reportTemplate != null && this.extractedArtifacts != null;
      if (this.inputsSet && this.viewInitialized) {
        this.calculatePageBreaks();
      }
    }
  }

  ngAfterViewChecked() {
    if (this.inputsSet && !this.viewInitialized) {
      this.viewInitialized = true;
      this.calculatePageBreaks();
    }
  }

  isRightAdjustedImages() {
    return this.viewOptionsCheckboxValues.find((option) => option.key === 'høyrejuster_bilder').value;
  }

  isViewLedetekster() {
    return this.viewOptionsCheckboxValues.find((option) => option.key === 'vis_ledetekster').value;
  }

  isViewLedeteksterOnOneLine() {
    return this.viewOptionsCheckboxValues.find((option) => option.key === 'ledetekst_og_verdi_på_en_linje').value;
  }

  isViewWithFullImage() {
    return this.viewOptionsCheckboxValues.find((option) => option.key === 'med_helbilde').value;
  }

  isShowSectionTitles() {
    return this.viewOptionsCheckboxValues.find((option) => option.key === 'vis_seksjonstitler').value;
  }

  calculatePageBreaks() {
    console.log('CALCULATING PAGE BREAKS');
    const elements = this.reportContainer.nativeElement.querySelectorAll('.report-artifact-object');
    const pageHeight = 1042; // A4 page height in pixels at 96 DPI

    let currentPosition = 0;

    elements.forEach((element: HTMLElement) => {
      const elementHeight = element.offsetHeight;
      currentPosition += elementHeight;

      console.log(currentPosition);

      if (currentPosition > pageHeight) {
        this.renderer.addClass(element, 'pdf-break-before');
        if (this.preview) {
          this.renderer.addClass(element, 'pdf-break-before-preview');
        }
        currentPosition = elementHeight;
      }
    });
  }


}
