import {Component, effect, Input, OnInit, WritableSignal} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MatRipple} from "@angular/material/core";
import {TranslateModule} from "@ngx-translate/core";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatButton, MatIconButton} from "@angular/material/button";
import {FocusServiceImplementation} from "../../search-focus.service";
import {MatActionList, MatListItem} from "@angular/material/list";
import {SearchContainer} from "../../../core/definitions/search-container";
import {MatCheckbox} from "@angular/material/checkbox";
import {FormsModule} from "@angular/forms";
import {Focus} from "../../../core/definitions/focus";
import {SearchHandlerService} from "../../search-handler.service";
import {CommonsService} from "../../../core/commons.service";
import {MatDialog} from "@angular/material/dialog";
import {SavedSearchDialogComponent} from "./saved-search-dialog/saved-search-dialog.component";
import {NgClass} from "@angular/common";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-filter-menu-saved-search-selector',
  standalone: true,
  imports: [
    MatIcon,
    MatRipple,
    TranslateModule,
    MatMenu,
    MatMenuTrigger,
    MatButton,
    MatActionList,
    MatListItem,
    MatCheckbox,
    FormsModule,
    MatIconButton,
    MatMenuItem,
    NgClass,
    MatTooltip
  ],
  templateUrl: './filter-menu-saved-search-selector.component.html',
  styleUrl: './filter-menu-saved-search-selector.component.scss'
})
export class FilterMenuSavedSearchSelectorComponent implements OnInit {
  @Input() fsi: FocusServiceImplementation;
  @Input() searchContainer: SearchContainer;
  @Input() trigger: WritableSignal<boolean>;

  currentMaxListLength = 10;
  currentTriggerValue = false;
  displayList: Array<Focus> = [];

  DEFAULT_MAX_LIST_LENGTH = 10;

  constructor(
    private commons: CommonsService,
    private matDialog: MatDialog,
    private searchHandlerService: SearchHandlerService
  ) {
    effect(() => {
      if (this.currentTriggerValue !== this.trigger()) {
        this.currentMaxListLength = this.DEFAULT_MAX_LIST_LENGTH;

        this.currentTriggerValue = this.trigger();
      }
    });
  }

  ngOnInit() {
    if (!this.fsi.getCurrentFocus()) {
      this.setCurrentFocus(this.fsi.createNewFocus());
    }

    if (this.fsi.getFocuses()) {
      this.getSetCurrentFocus();
    }

    this.updateDisplayList();
  }

  activeFocusHasChanges(): boolean {
    if (this.searchContainer.focus.curFocusId) {
      return this.fsi.focusHasChanges(this.searchContainer.focus.curFocus);
    }

    return false;
  }

  checkboxClicked(event: MouseEvent): void {
    event.stopPropagation();

    this.fsi.toggleFocusIsDefault(this.searchContainer.focus.curFocus);
    this.fsi.createOrUpdateFocus(this.searchContainer.focus.curFocus, this.searchContainer.focus.curFocus.private).then();
  }

  async clearSetFocus(event: MouseEvent) {
    event.stopPropagation();

    if (this.searchContainer.focus.curFocusId) {
      this.searchContainer.focus.curFocusId = null;
      this.searchContainer.focus.curFocus = null;
      this.searchContainer.focus.currentFocusName = null;

      this.setCurrentFocus(this.fsi.createNewFocus());

      await this.searchHandlerService.goPathView(this.searchContainer.path, this.searchContainer);
    }
  }

  createFocusFromSearch(event: MouseEvent) {
    event.stopPropagation();

    const dialogRef = this.matDialog.open(SavedSearchDialogComponent, {
      data: {
        editMode: false,
        fsi: this.fsi
      }
    });

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        if (this.searchContainer.focus.curFocus?.focus.name === '') {
          this.searchContainer.focus.curFocus.focus.name = data.givenName;
        }

        if (data.setAsDefault) {
          this.searchContainer.focus.curFocus.focus.isDefault = true;
        }

        try {
          let savedFocus: Focus;
          if (this.searchContainer.focus.curFocus.focus.stored) {
            savedFocus = await this.fsi.storeFocusAsNewFocus(this.searchContainer.focus.curFocus, data.givenName);
          }
          else {
            savedFocus = await this.fsi.createOrUpdateFocus(this.searchContainer.focus.curFocus);
          }

          if (savedFocus && data.setOnClose) {
            this.setCurrentFocus(savedFocus);
          }

          this.updateDisplayList();

          if (data.setOnClose) {
            this.setCurrentFocus(this.fsi.getCurrentFocus());
            await this.searchHandlerService.goPathView(this.fsi.getCurrentFocus().focus.homePath, this.searchContainer, true);
          }
        }
        catch(error) {
          console.error(error);
        }
      }
    });
  }

  async deleteFocus(event: MouseEvent, focus: Focus) {
    event.stopPropagation();

    const focusId = focus.focusId;

    await this.fsi.deleteFocus(focusId);

    this.updateDisplayList();

    if (focusId === this.searchContainer.focus.curFocusId) {
      this.searchContainer.focus.curFocusId = null;
    }

    if (this.searchContainer.focus.curFocusId === null) {
      this.searchContainer.focus.curFocus = null;
      this.searchContainer.focus.currentFocusName = null;
      await this.searchHandlerService.goPathView(this.searchContainer.path, this.searchContainer);
    }

    if (!this.fsi.getCurrentFocus()) {
      this.setCurrentFocus(this.fsi.createNewFocus());
    }
  }

  editFocus(event: MouseEvent, focus: Focus) {
    event.stopPropagation();

    const dialogRef = this.matDialog.open(SavedSearchDialogComponent, {
      data: {
        currentName: focus.focus.name,
        editMode: true,
        fsi: this.fsi
      }
    });

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        if (focus.focus.stored) {
          focus.focus.name = data.givenName;

          try {
            await this.fsi.createOrUpdateFocus(focus);

            this.updateDisplayList();
          }
          catch(error) {
            console.error(error);
          }
        }
      }
    });
  }

  itemActionButtonClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  async setFocus(focus: Focus, event: MouseEvent) {
    event.stopPropagation();

    if (focus) {
      await this.fsi.loadSavedFocuses();
      this.setCurrentFocus(focus);
      await this.searchHandlerService.goPathView(focus.focus.homePath, this.searchContainer, true);
    }
  }

  setSearchFromFocus(focus: Focus): void {
    let pathParams = null;

    for (const key of Object.keys(focus.focus.pathParams)) {
      if (focus.focus.pathParams[key] !== null) {
        pathParams = focus.focus.pathParams[key];
      }
    }

    if (pathParams !== null) {
      if (pathParams.hasOwnProperty('filtersFacets.checkedFilters')) {
        this.searchContainer.filtersFacets.checkedFilters = {};

        for (const key of Object.keys(pathParams['filtersFacets.checkedFilters'])) {
          this.searchContainer.filtersFacets.checkedFilters[key] = [true];
        }

        this.searchContainer.filtersFacets.checkedFilters = pathParams['filtersFacets.checkedFilters'];
      }

      if (pathParams.hasOwnProperty('advancedSearchParams')) {
        this.searchContainer.advancedSearchParams = pathParams.advancedSearchParams;
      }

      if (pathParams.hasOwnProperty('queryContainer.query')) {
        this.searchContainer.queryContainer.query = pathParams['queryContainer.query']
      }

      this.searchHandlerService.goPathView(focus.focus.homePath, this.searchContainer, true).then();
    }
  }

  showMoreOfList(event: MouseEvent) {
    event.stopPropagation();

    if (this.currentMaxListLength <= this.displayList.length) {
      this.currentMaxListLength += this.DEFAULT_MAX_LIST_LENGTH;
    }
  }

  private getSetCurrentFocus() {
    this.setCurrentFocus(this.fsi.getCurrentFocus());
  }

  private setCurrentFocus(focus: Focus) {
    this.fsi.setCurrentFocus(focus ? focus.focusId : null);

    this.searchContainer.focus.curFocus = this.commons.copy(focus);
  }

  private updateDisplayList() {
    const listCopy: Array<Focus> = this.commons.copy(this.fsi.getFocuses());

    this.displayList = listCopy;
  }
}
