<div #reportContainer class="report-preview-area">
  @for (artifact of extractedArtifacts; track artifact.artifactId; let idx = $index) {
    <div class="report-artifact-object">

      <div
        [ngClass]="{'report-artifact-object-fields-image-full': isViewWithFullImage(), 'report-artifact-object-fields-left': !isViewWithFullImage() && isRightAdjustedImages(), 'report-artifact-object-fields-right': !isViewWithFullImage() && !isRightAdjustedImages()}">
        <div class="report-artifact-field-image">
          <div class="report-artifact-field-image-box">
            @if (isViewWithFullImage()) {
              <img [src]="imageUrls.get(artifact.thumbnailImage.image_id)" class="report-artifact-field-image-img"  alt="bilde"/>
            } @else if (artifact.thumbnailImage) {
              <app-thumbnail
                [item]="artifact.thumbnailImage"
                [stylingClass]="'report-artifact-field-image-img'"></app-thumbnail>
            }
          </div>
        </div>
      </div>
      <h1>{{ artifact.artifactName }}</h1>
        @for (section of artifact.sections; track section.id) {
          @if (isShowSectionTitles()) {
            <h2>{{section.title | translate}} </h2>
          }

          @for (field of section.fields; track field.key; let isEven = $even; let isFirst = $first) {
            <div
              [ngClass]="{'report-artifact-grid': isViewLedetekster() && isViewLedeteksterOnOneLine(), 'report-artifact-grid-one-col': !(isViewLedetekster() && isViewLedeteksterOnOneLine()), 'report-artifact-grid-even': !field.hasHeader1Or2 && isEven, 'report-artifact-grid-first': isFirst}">
            @if (isViewLedetekster()) {
              <div class="report-artifact-field-title">
                <span>{{ field.key }}</span>
              </div>
            }
            <div class="report-artifact-field-values" [ngClass]="{'report-artifact-values-table' : field.hasHeader1Or2}">
                @for (value of field.value; track $index; let isEven2 = $even) {
                  <ul class="report-artifact-value-list" [ngClass]="{'report-artifact-value-list-table' : field.hasHeader1Or2}">
                  @if (value.headersLevel1) {
                    <li class="report-artifact-value-header-level-1">
                      {{ value.headersLevel1 }}
                    </li>
                  }
                  <li>
                    <div [ngClass]="{'report-artifact-field-values-grid': isViewLedetekster() && isViewLedeteksterOnOneLine(), 'report-artifact-field-values-grid-one-col': !(isViewLedetekster() && isViewLedeteksterOnOneLine())}">
                      @if (value?.headersLevel2 || value?.labels) {
                        <div class="report-artifact-field-values-grid-left">
                          @if (value.headersLevel2) {
                            {{ value.headersLevel2 }}
                          }
                          @if (value.labels) {
                            <span class="report-artifact-value-label">{{ value.labels }}</span>
                          }
                        </div>
                      }
                      <div class="report-artifact-field-values-grid-right">
                        {{ value.values }}
                      </div>
                    </div>
                  </li>
                  </ul>
                }

            </div>
            </div>
          }
        }
      </div>
  }
  <div id="endOfReport"></div>
</div>
