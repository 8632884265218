<div class="report-preview-area">
  @if (isShowTableHeader()) {
    <div class="report-list-artifact-header"
         [ngClass]="{'report-list-artifact-header-medium' : selectedPhotographSize === 'medium', 'report-list-artifact-header-large' : selectedPhotographSize === 'large'}">
      <div class="report-list-artifact-column"></div>
      <div class="report-list-artifact-column">ID</div>
      @for (header of reportHeaderColumns; track header) {
        <div class="report-list-artifact-column">{{ header }}</div>
      }
    </div>
  }
  @for (artifact of extractedArtifacts; track artifact.artifactId; let idx = $index, isEven = $even) {
    <div class="report-list-artifact-object" [ngClass]="{'report-list-artifact-object-no-grid' : numPhotographsInReport > 1 || isAllImagesSelected(),'report-list-artifact-object-medium' : selectedPhotographSize === 'medium','report-list-artifact-object-large' : selectedPhotographSize === 'large'}">
      @if (numPhotographsInReport == 1 && !isAllImagesSelected()) {
        <div class="report-list-artifact-image">
          <div class="report-artifact-field-image-box">
            @if (selectedPhotographSize === 'large') {
              <img [src]="imageUrls.get(artifact.thumbnailImage.image_id)" class="report-artifact-field-image-img"  alt="bilde"/>
            } @else if (artifact.thumbnailImage) {
              <app-thumbnail
                [item]="artifact.thumbnailImage"
                [stylingClass]="'report-artifact-field-image-img'"></app-thumbnail>
            } @else {
              <div class="default-image report-picture-missing">
                <span>{{'TRANS__HAS_IMAGES__NONE' | translate}}</span>
              </div>
            }
          </div>
        </div>
      }

      <div class="report-list-fields-grid"
        [ngClass]="{'report-list-fields-grid-collapse' : numPhotographsInReport == 1 && selectedPhotographSize === 'large'}">
        <div class="report-list-artifact-column">
          @if (!isShowTableHeader()) {
            <div class="report-list-artifact-column-header">ID</div>
          }

          {{ artifact.artifactName }}
        </div>

        @for (section of artifact.sections; track section.id) {
          @for (field of section.fields; track $index; let isEven = $even; let isFirst = $first) {
            <div class="report-list-artifact-column">
              @for (value of field.value; track $index; let isEven2 = $even, index = $index) {
                @if (!isShowTableHeader() && index === 0) {
                  <div class="report-list-artifact-column-header">{{ field.key }}</div>
                }
                {{ value.values }}
              }
            </div>
          }
        }
      </div>

        @if (numPhotographsInReport > 1 || isAllImagesSelected()) {
          <div class="report-list-fields-grid report-list-artifact-images"
               [ngClass]="{'report-list-artifact-images-medium' : selectedPhotographSize === 'medium', 'report-list-artifact-images-large' : selectedPhotographSize === 'large'}">
          @for (image of artifact.images; track image; let index = $index) {
            @if (isAllImagesSelected() || index < numPhotographsInReport) {
              <div class="report-list-field-grid-image">
                @if (selectedPhotographSize === 'large') {
                  <img [src]="imageUrls.get(image.image_id)" class="report-artifact-field-image-img"  alt="bilde"/>
                } @else {
                  <app-thumbnail
                    [item]="image"
                    [stylingClass]="'report-artifact-field-image-img'"></app-thumbnail>
                }

              </div>
            }
          }
          </div>
        }
    </div>
  }
</div>
